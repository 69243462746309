import * as React from 'react';

export const MinusIcon: React.FC<{}> = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 10a1 1 0 01-.7-.3A1 1 0 011 9c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h14c.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 01-.7.3H2z"
      />
    </svg>
  );
};

MinusIcon.displayName = 'MinusIcon';
