export const MAX_ZOOM = 16;
export const DEFAULT_ZOOM = 9;

export const formatZoom = (zoom?: number, defaultZoom: number = DEFAULT_ZOOM) => {
  if (!zoom) {
    return defaultZoom;
  }

  return zoom > MAX_ZOOM ? MAX_ZOOM : zoom;
};
