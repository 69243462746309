import * as React from 'react';
import * as styles from './OfficeMapWrapper.css';
import { PropsWithChildren } from 'react';

export const OfficeMapWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <h3 className={styles['offices-map-title']}>Офисы продаж</h3>
      {children}
    </>
  );
};
