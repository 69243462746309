import * as React from 'react';
import { ILogger } from '@cian/logger';
import { ModalFullscreen } from '@cian/ui-kit';

import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';

import { formatCoordinates, formatZoom, getIconLayout, getMainOffice, getSVGPinImage, MAX_ZOOM } from './utils';
import { loadYmapsApi } from '../../utils/yamaps';
import { IMapOffice, IMapSettings } from '../../types/specialPromo';

import { OfficePreview } from './components/OfficePreview';
import { ZoomControls } from './components/ZoomControls';
import * as styles from './OfficesMap.css';

interface IPlacemark {
  events: {
    add(eventType: string, callback: (e?: Event) => void): void;
  };
}

const MAP_MODULES = ['Map', 'Placemark', 'templateLayoutFactory'];

export interface IOfficeMapData {
  id: number;
  address: string;
  isMain: boolean;
  coordinates: {
    lat: number;
    lng: number;
  };
  name: string;
  phone: string;
}

interface IOfficesMapProps {
  mapId: string;
  offices: IMapOffice[];
  logger: ILogger;
  mapSettings: IMapSettings;
  mainColor: string;
  pinImageUrl?: string;
}

interface IOfficesMapState {
  isOpenMap: boolean;
  selectedOffice?: IMapOffice;
}

export class OfficesMap extends React.PureComponent<IOfficesMapProps, IOfficesMapState> {
  private pinLayout: Function;
  private map: IYmapsMap | null = null;
  private ymaps: YMaps.IYMaps | null = null;

  public state: IOfficesMapState = {
    isOpenMap: false,
  };

  public componentWillUnmount() {
    this.destroyMap();
  }

  public render() {
    const { mainColor, pinImageUrl } = this.props;
    const { isOpenMap, selectedOffice } = this.state;

    return (
      <div className={styles['container']}>
        <div
          className={styles['map-preview']}
          onClick={this.openMap}
          style={this.getBackgroundStyles()}
          role="button"
          data-testid="MapPreview"
        >
          <div className={styles['map-preview-pin']}>{getSVGPinImage(mainColor, pinImageUrl)}</div>
        </div>
        <ModalFullscreen open={isOpenMap} onClose={this.closeMap} theme="white">
          <div className={styles['map-container']}>
            <div className={styles['header']}>
              <h3 className={styles['header-title']}>Офисы продаж</h3>
              <button onClick={this.closeMap} className={styles['close-button']} type="button">
                <IconActionClose16 color="icon-inverted-default" />
              </button>
            </div>
            <div className={styles['controls-container']}>
              <ZoomControls onZoomIn={this.zoomIn} onZoomOut={this.zoomOut} />
            </div>
            <div id={this.props.mapId} className={styles['map']} />
            {selectedOffice && (
              <OfficePreview
                office={selectedOffice}
                onClose={this.closeOfficeInfo}
                color={this.props.mainColor}
                open={true}
              />
            )}
          </div>
        </ModalFullscreen>
      </div>
    );
  }

  private zoomIn = () => {
    if (this.map) {
      this.map.setZoom(this.map.getZoom() + 1);
    }
  };

  private zoomOut = () => {
    if (this.map) {
      this.map.setZoom(this.map.getZoom() - 1);
    }
  };

  private closeOfficeInfo = () => this.setState({ selectedOffice: undefined });

  private destroyMap() {
    if (this.map && this.map.destroy) {
      this.map.destroy();
      this.map = null;
    }
  }

  private getBackgroundStyles = () => {
    const mainOffice = getMainOffice(this.props.offices);
    const { lat, lng } = mainOffice.coordinates;

    const url = `https://static-maps.yandex.ru/1.x/?ll=${lng},${lat}&size=650,300&z=16&l=map`;

    return {
      backgroundImage: `url(${url})`,
      backgroundPosition: 'center',
    };
  };

  private createPlacemark = (mapObject: IOfficeMapData) => {
    if (!this.map || !this.ymaps) {
      return;
    }

    const { coordinates } = mapObject;

    const placemark: IPlacemark = new this.ymaps.Placemark(
      [coordinates.lat, coordinates.lng],
      {},
      {
        iconImageSize: [46, 55],
        iconLayout: this.pinLayout,
        iconOffset: [-23, -55],
        iconShape: {
          coordinates: [33, 23],
          radius: 23,
          type: 'Circle',
        },
      },
    );

    placemark.events.add('click', () => this.setState({ selectedOffice: mapObject }));

    this.map.geoObjects.add(placemark);
  };

  private closeMap = () => {
    this.setState({
      isOpenMap: false,
      selectedOffice: undefined,
    });
  };

  private openMap = () => {
    this.setState({ isOpenMap: true });
    this.initMap();
  };

  private initMap = async () => {
    this.destroyMap();

    const { mainColor, offices, mapId, mapSettings, pinImageUrl, logger } = this.props;

    try {
      const ymaps = await loadYmapsApi({ require: MAP_MODULES });

      this.ymaps = ymaps;
      this.pinLayout = getIconLayout(ymaps, mainColor, pinImageUrl);
      this.map = new ymaps.Map(
        mapId,
        {
          center: formatCoordinates(mapSettings.lat, mapSettings.lng),
          zoom: formatZoom(mapSettings.zoom),
        },
        {
          // чинит баг с перерисовкой карты на ios после скрытия нативных панелей браузера
          // https://tech.yandex.ru/maps/doc/jsapi/2.0/ref/reference/Map-docpage/#param-options.autoFitToViewport
          autoFitToViewport: 'always',
          maxZoom: MAX_ZOOM,
        },
      );

      offices.forEach(this.createPlacemark);
    } catch (error) {
      logger.error(error, { domain: 'components#OfficesMap' });
    }
  };
}
