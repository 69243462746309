import * as React from 'react';

export const AddIcon: React.FC<{}> = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 8H2a1 1 0 100 2h6v6c0 .6.4 1 1 1s1-.4 1-1v-6h6a1 1 0 100-2h-6V2a1 1 0 10-2 0v6z"
      />
    </svg>
  );
};

AddIcon.displayName = 'AddIcon';
