import * as React from 'react';
import { useSelector } from 'react-redux';
import { OfficesMap, OfficeMapWrapper } from '../../components/OfficesMap';
import { Section } from '../../components/Section';
import { getPromoInfo } from '../../selectors/specialPromo';
import { useApplicationContext } from '../../utils/applicationContext';

// eslint-disable-next-line import/no-default-export
export default function OfficesMapContainer() {
  const promoInfo = useSelector(getPromoInfo);
  const { logger } = useApplicationContext();

  if (!promoInfo || !promoInfo.officeList.length) {
    return null;
  }

  return (
    <Section>
      <OfficeMapWrapper>
        <OfficesMap
          offices={promoInfo.officeList}
          mapSettings={promoInfo.mapSettingsOffice}
          mainColor={promoInfo.colorHex}
          pinImageUrl={promoInfo.pinImage}
          logger={logger}
          mapId="offices-map"
        />
      </OfficeMapWrapper>
    </Section>
  );
}
