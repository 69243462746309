import * as React from 'react';

export const getSVGPinImage = (color: string, insideImageUrl?: string) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="62"
    height="69"
    viewBox="0 0 62 69"
  >
    <defs>
      <path
        id="map-placemark-a"
        d="M20.28 45.84C8.86 44.496 0 34.783 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 11.782-8.86 21.495-20.28 22.84L23 54l-2.72-8.16z"
      />
      <filter id="map-placemark-b" width="169.6%" height="159.3%" x="-34.8%" y="-14.8%" filterUnits="objectBoundingBox">
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(8)">
      <ellipse cx="23" cy="53.5" fill="var(--decorative-theme-black)" fillOpacity=".15" rx="5" ry="1.5" />
      <mask id="map-placemark-c" fill="var(--decorative-theme-white)">
        <use xlinkHref="#map-placemark-a" />
      </mask>
      <use fill="var(--decorative-theme-black)" filter="url(#map-placemark-b)" xlinkHref="#map-placemark-a" />
      <use fill="#FFF" xlinkHref="#map-placemark-a" />
      <path
        fill={color}
        fillOpacity=".2"
        d="M23 43c11.046 0 20-8.954 20-20S34.046 3 23 3 3 11.954 3 23s8.954 20 20 20z"
        mask="url(#map-placemark-c)"
      />
      {insideImageUrl ? (
        <image xlinkHref={insideImageUrl} x="13" y="13" height="20px" width="20px" />
      ) : (
        <g mask="url(#map-placemark-c)">
          <path fill="none" d="M13 13h20v20H13z" />
          <path fill="#00AEEF" d="M18 13h5v5h-5z" />
          <path fill="#DB0278" d="M18 18h8v6H13zM23 24h7v8H15z" />
          <path fill="#00AEEF" d="M25.467 17L32 24H18zM15 24h8l-8 8z" />
          <path fill="#15244D" d="M15 24h15l-8 8z" />
        </g>
      )}
    </g>
  </svg>
);
