import * as React from 'react';
import { ModalWindow } from '@cian/ui-kit';

import { formatPhone } from '../../../../utils/formatters/phone';
import { IMapOffice } from '../../../../types/specialPromo';

import * as styles from './OfficePreview.css';

interface IOfficePreviewProps {
  color: string;
  office: IMapOffice;
  onClose(): void;
  open: boolean;
}

export function OfficePreview({ color, office, open, onClose }: IOfficePreviewProps) {
  return (
    <ModalWindow open={open} onClose={onClose} maxWidth={400} width="100%">
      <div className={styles['container']} data-testid="OfficePreview">
        <div className={styles['name']}>{office.name}</div>
        <div className={styles['address']}>{office.address}</div>
        <a href={`tel:+${office.phone}`} className={styles['phone']} style={{ borderColor: color, color }}>
          {formatPhone(office.phone)}
        </a>
      </div>
    </ModalWindow>
  );
}
