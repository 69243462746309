import * as React from 'react';
import { AddIcon, MinusIcon } from './Icons';
import * as styles from './ZoomControls.css';

interface IZoomControlsProps {
  onZoomIn(): void;
  onZoomOut(): void;
}

export function ZoomControls({ onZoomIn, onZoomOut }: IZoomControlsProps) {
  return (
    <div className={styles['container']}>
      <button className={styles['button']} onClick={onZoomIn} type="button" data-testid="zoom-in">
        <AddIcon />
      </button>
      <button className={styles['button']} onClick={onZoomOut} type="button" data-testid="zoom-out">
        <MinusIcon />
      </button>
    </div>
  );
}
